$(document).ready(function(){
	$(window).load(function(){

    $(document).on('click', '.secret-email-modal', function(e) {
      e.preventDefault();
      $('.email-modal').modal('show');
    });

		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

    $('#add-more-files').on('click', function(e) {
      e.preventDefault();
      var count = $('.wrapper-file .attachment').length;
      var newElement = '<div class="files">' +
                       '<button class="upload-file">Upload File Attachment</button><span class="file-name"></span>' +
                       '<span class="file-error"></span>'+
                       '<input type="file" name="file['+count+']" class="d-none attachment">' +
                       '</div>';
      $('.wrapper-file').append(newElement);
    });

    $('.wrapper-file').on('click', '.upload-file', function() {
      var thiss = $(this);
      $(this).siblings('.attachment').click();
      $(this).siblings('.attachment').on('change', function() {
        var $file_val = $(this).val().replace(/C:\\fakepath\\/i, '');
        $(thiss).siblings('.file-name').text($file_val);
        $('#reg-username').val($file_val);
        $(thiss).siblings('.file-name').text($file_val);
      });
    });


		// INITIATIONS
		AOS.init();
    $('.equal-height').matchHeight({byRow:false});
		// CUSTOM FUNCTIONS
    calculate_monthly_amortization();
    file_accordion();
    objLightcase();
		custom_file();
		display_slick();
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();
		loader();
		search();
    fullpage_func();

    $(document).on('click', '.address-map', function(e) {
      e.preventDefault();
      $('.acf-map').fadeOut();
      $('#map-'+$(this).data('id')).fadeIn();
    });

		$(document).on('click', '.prev-btn', function(){
			window.history.back();
		});

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
    fullpage_func();

	});

});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
	equalizer();
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	equalizer();
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	equalizer();
}
else{
}

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/

function pre( $object ){
	console.log( $object );
}

function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight, $navHeight, $footerHeight ){
	var $pgHeight, $nvHeight, $ftHeight;
	
	if( $pageHeight ) {
		$pgHeight = $pageHeight;
	} else {
		$pgHeight = 0;
	}

	if( $navHeight ) {
		$nvHeight = $navHeight;
	} else {
		$nvHeight = 0;
	}

	if( $footerHeight ) {
		$ftHeight = $footerHeight;
	} else {
		$ftHeight = 0;
	}

	$('.wrapper-holder').css({
		'min-height': $pgHeight - $nvHeight,
		//'margin-top': $nvHeight,
		'padding-bottom': $ftHeight
	});
	$('.main-layout').css({
		'min-height': $pgHeight - ( $nvHeight + $ftHeight ),
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out" );
		$(this).toggleClass('toggle-mobile-menu');
		if(!$('.sidepanel').hasClass('sidepanel-out')){
			$('.close-sidemenu').hide();
		} else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  //location = sitelink + '/thank-you/';
      $('.email-modal').modal('hide');
      $('.thankyou-modal').modal('show');
      $btn.removeClass('loading');
      $ajaxLoader.removeClass('visible');
      $('.file-name').text('');
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}


function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase();
}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function equalizer(){
	var maxHeight = 0;
	$(".equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".equalize").height(maxHeight);

	var productHeight = 0;
	$(".hp-equalize").each(function(){
	   if ($(this).height() > productHeight) { productHeight = $(this).height(); }
	});
	$(".hp-equalize").height(productHeight);
}

function caretMenu(){
	$('.desktop-menu .nav .menu li').each( function(){
		var menu = $(this);
		if( menu.find('.sub-menu').length > 0 ) {
			menu.find('a').first().append('<span class="fas fa-angle-down"></span>');
		}
	});
}

// IOS INPUT BUG FIX
(function iOS_CaretBug() {
	var ua = navigator.userAgent,
	scrollTopPosition,
	iOS = /iPad|iPhone|iPod/.test(ua),
	iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

	// ios 11 bug caret position
 	if ( iOS && iOS11 ) {

		$(document.body).on('show.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Get scroll position before moving top
				scrollTopPosition = $(document).scrollTop();

				// Add CSS to body "position: fixed"
				$("body").addClass("iosBugFixCaret");
			}
		});

     $(document.body).on('hide.bs.modal', function(e) {
			if ( $(e.target).hasClass('modal') ) {
				// Remove CSS to body "position: fixed"
				$("body").removeClass("iosBugFixCaret");

				//Go back to initial position in document
				$(document).scrollTop(scrollTopPosition);
			}
     });
   }
})();

function loader(){
	setTimeout(function(){
		$('.loader-overlay').addClass('animate');
	}, 200);
	setTimeout(function(){
		$('.loader-overlay').addClass('animated');
	}, 1000);
	setTimeout(function(){
		$('.loader-overlay').fadeOut(200);
	}, 1200);
}

function search(){
	$(document).on('click', '.search-btn, .cs-backdrop', function(){
		$('.search-form, .cs-backdrop').toggleClass('active');
		$('.search-form.active input').select();
	});

	$(document).on('click', '.close-search', function(){
		$('.search-form, .cs-backdrop').toggleClass('active');
	});
}

function display_slick() {
	$('.ads-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
	});
	$('.ethics-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
	});
  $('.banner-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
  });
	$('.enterprise-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
	});
	$('.img-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    cssEase: 'linear',
    fade: true,
    autoplay: true,
	});
	$('.ft-proj-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
            	slidesToShow: 2,
    					slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
            	slidesToShow: 1,
    					slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
            	slidesToShow: 1,
    					slidesToScroll: 1,
            }
        }
    ]
	});
  $('.model-unit-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: false,
    autoplay: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        }
    ]
  });
  $('.gallery-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    autoplay: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            }
        },
        {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            }
        }
    ]
  });
}

function custom_file() {
	$('#upload-resume-btn').on('click', function() {
		$('#careerResume').click();
	});
	$('#careerResume').on('change', function() {
		var file_name	=	$(this).val().replace(/C:\\fakepath\\/i, '');
		$('.file-name').text(file_name);
	});
}

function file_accordion() {
  $('.file-accordion').on('click', '.first-file-wrap', function(e) {
    e.preventDefault();
    var $this = $(this);
    if( $this.hasClass('is-active') ) {
      $this.removeClass('is-active');
      $this.siblings('.parent-accordion').slideUp();
    } else {
      $this.addClass('is-active');
      $this.siblings('.parent-accordion').slideDown();
    }
  });
  $('.files-parent').on('click', '.second-file-wrap', function(e) {
    e.preventDefault();
    var $this = $(this);
    if( $this.hasClass('is-active') ) {
      $this.removeClass('is-active');
      $this.siblings('.download-wrapper').slideUp();
    } else {
      $this.addClass('is-active');
      $this.siblings('.download-wrapper').slideDown();
    }
  });
}

function calculate_monthly_amortization() {

  $('#down-payment').on('change', function() {
    var estimated_price = $('#esp').data('value'),
        loan_balance = 0,
        dp_amount = 0;
    if( $(this).val() === "none" ) {
      $('#loan_balance').val("");
    } else {
      loan_balance = estimated_price - $(this).val();
      dp_amount = $(this).val() - 0;
      $('#dp-amount').val(numberWithCommas(dp_amount.toFixed(2)));
      $('#loan-amount').val(numberWithCommas(loan_balance.toFixed(2)));
      //$('#loan-balance').val(numberWithCommas(loan_balance.toFixed(2)));
    }
  });

  $('#years-to-pay').on('change', function() {
    $('#eir').val($(this).val()+'%');
  });

  $('#calculate').on('click', function() {
    var $years = $('#years-to-pay').val(),
        $loan_amount = $('#loan-amount').val();
        if( $years != "none" && $loan_amount != 'none' ) {
          var $monthly_amort = ($loan_amount * $years) / $('#currency').val(),
              $currency_sign = '';

          if( $('#currency').val() == 1 ) {
            $currency_sign = '\u20B1';
          } else {
            $currency_sign = '\u0024';
          }
          $('#monthly').val( $currency_sign + '' + numberWithCommas($monthly_amort.toFixed(2)) );
        } else {
          $('#monthly').val();
        }
  });
}

function numberWithCommas(number) {
    var parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

function fullpage_func() {

  if( !$('.edgebrowser').is(':visible') || !$('.windowsinternetexplorer').is(':visible') ) {

    if( Modernizr.mq('(min-width: 1200px)') ) {
      $('#fullpage').fullpage({
        onLeave: function(origin, destination, direction){
          var leavingSection = this;

          //after leaving section 1
          if(origin.index == 0 && direction =='down'){
            $('.main-header').addClass('push-top');
            $('.back-to-top').css('display', 'block');
          }
          else if(origin.index == 1 && direction == 'up'){
            $('.main-header').removeClass('push-top');
            $('.back-to-top').css('display', 'none');
          }
        },
        afterResize: function(width, height){
          var fullpageContainer = this;
        }
      });
    }
  }

  $('.back-to-top .arrow-up').on('click', function() {
    $.fn.fullpage.moveTo(1);
    $('.main-header').removeClass('push-top');
    $('.back-to-top').css('display', 'none');
  });
}